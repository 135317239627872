import { render, staticRenderFns } from "./receivableList.vue?vue&type=template&id=a31f8932&scoped=true"
import script from "./receivableList.vue?vue&type=script&lang=js"
export * from "./receivableList.vue?vue&type=script&lang=js"
import style0 from "./receivableList.vue?vue&type=style&index=0&id=a31f8932&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a31f8932",
  null
  
)

export default component.exports